import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import * as ROUTES from './routes';

Vue.use(VueRouter);

const routes = [
    {
        path: ROUTES.ROOT.path,
        component: () => {
            return import('@/components/Auth/index.vue');
        },
        children: [
            {
                path: ROUTES.ROOT.path,
                name: ROUTES.ROOT.name,
                redirect: ROUTES.EMPLOYER_SIGNUP.path,
            },
            {
                path: ROUTES.FORGOT_PASSWORD.path,
                name: ROUTES.FORGOT_PASSWORD.name,
                component: () => {
                    return import('@/components/ForgotPassword.vue');
                },
            },
            {
                path: ROUTES.RESET_PASSWORD.path,
                name: ROUTES.RESET_PASSWORD.name,
                component: () => {
                    return import('@/components/ResetPassword.vue');
                },
            },
            {
                path: ROUTES.RESET_CONFIRMATION.path,
                name: ROUTES.RESET_CONFIRMATION.name,
                component: () => {
                    return import('@/components/ResetConfirmation.vue');
                },
            },
        ],
    },
    {
        path: ROUTES.EMPLOYER_SIGNUP.path,
        name: ROUTES.EMPLOYER_SIGNUP.name,
        component: () => {
            return import('@/components/Onboarding/Enterprise/Signup.vue');
        },
        meta: {
            alreadyLoggedIn: true,
        },
    },
    {
        path: ROUTES.VERIFICATION_CODE.path,
        name: ROUTES.VERIFICATION_CODE.name,
        component: () => {
            return import('@/components/Onboarding/Enterprise/OTPVerification.vue');
        },
    },
    {
        path: ROUTES.ENTERPRISE_ONBOARDING_PLANS.path,
        name: ROUTES.ENTERPRISE_ONBOARDING_PLANS.name,
        meta: {
            requiresAuth: true,
        },
        component: () => {
            return import('@/components/Onboarding/Enterprise/Plans.vue');
        },
    },
    {
        path: ROUTES.EMPLOYER_SIGNIN.path,
        name: ROUTES.EMPLOYER_SIGNIN.name,
        component: () => {
            return import('@/components/Signin.vue');
        },
        meta: {
            alreadyLoggedIn: true,
        },
    },
    {
        path: ROUTES.ACCOUNT_ROOT.path,
        component: () => {
            return import('@/components/Account/index.vue');
        },
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: ROUTES.ACCOUNT_ROOT.path,
                redirect: ROUTES.ACCOUNT_DASHBOARD.path,
                component: () => {
                    return import('@/components/Account/TemplateWithFilter.vue');
                },
                children: [
                    {
                        path: ROUTES.ACCOUNT_DASHBOARD.path,
                        name: ROUTES.ACCOUNT_DASHBOARD.name,
                        component: () => {
                            return import('@/components/Account/Search/index.vue');
                        },
                    },
                    {
                        path: ROUTES.ACCOUNT_SEARCH.path,
                        name: ROUTES.ACCOUNT_SEARCH.name,
                        component: () => {
                            return import('@/components/Account/Search/index.vue');
                        },
                    },
                ],
            },
            {
                path: ROUTES.TALENT_DETAILS.path,
                name: ROUTES.TALENT_DETAILS.name,
                component: () => {
                    return import('@/components/Account/Search/TalentDetailsPage.vue');
                },
            },
            {
                path: ROUTES.ACCOUNT_SETTINGS.path,
                component: () => {
                    return import('@/components/Account/Settings/index.vue');
                },
                children: [
                    {
                        path: ROUTES.ACCOUNT_SETTINGS.path,
                        name: ROUTES.ACCOUNT_SETTINGS.name,
                        component: () => {
                            return import('@/components/Account/Settings/Edit.vue');
                        },
                    },
                    {
                        path: ROUTES.PAYMENT_METHOD.path,
                        name: ROUTES.PAYMENT_METHOD.name,
                        component: () => {
                            return import('@/components/Account/Settings/Subscription.vue');
                        },
                    },
                    {
                        path: ROUTES.COMPANY_INFO.path,
                        name: ROUTES.COMPANY_INFO.name,
                        component: () => {
                            return import('@/components/Account/Settings/CompanyInfo/index.vue');
                        },
                        meta: {
                            requiresAdmin: true,
                        },
                    },
                    {
                        path: ROUTES.ACCOUNT_TEAM.path,
                        name: ROUTES.ACCOUNT_TEAM.name,
                        component: () => {
                            return import('@/components/Account/Settings/TeamPage/index.vue');
                        },
                        meta: {
                            requiresAdmin: true,
                        },
                    },
                    {
                        path: ROUTES.ACCOUNT_DOWNLOADS.path,
                        name: ROUTES.ACCOUNT_DOWNLOADS.name,
                        component: () => {
                            return import('@/components/Account/Settings/downloads-section/index.vue');
                        },
                    },
                ],
            },
        ],
    },
    {
        path: ROUTES.JOBS.path,
        component: () => {
            return import('@/components/Jobs/index.vue');
        },
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: ROUTES.JOBS.path,
                name: ROUTES.JOBS.name,
                component: () => {
                    return import('@/components/Jobs/Jobs.vue');
                },
            },
            {
                path: ROUTES.JOB_DETAILS.path,
                name: ROUTES.JOB_DETAILS.name,
                component: () => {
                    return import('@/components/Jobs/Jobs.vue');
                },
            },
            {
                path: ROUTES.ARCHIVED_JOBS.path,
                name: ROUTES.ARCHIVED_JOBS.name,
                component: () => {
                    return import('@/components/Jobs/Archived/index.vue');
                },
            },
            {
                path: ROUTES.ARCHIVED_JOB_DETAILS.path,
                name: ROUTES.ARCHIVED_JOB_DETAILS.name,
                component: () => {
                    return import('@/components/Jobs/Archived/index.vue');
                },
            },
            {
                path: ROUTES.ADD_JOB.path,
                name: ROUTES.ADD_JOB.name,
                component: () => {
                    return import('@/components/Jobs/Add/index.vue');
                },
            },
            {
                path: 'edit/:id',
                name: 'edit-job',
                component: () => {
                    return import('@/components/Jobs/Edit/index.vue');
                },
            },
        ],
    },
    {
        path: ROUTES.TALENT_PROFILE.path,
        name: ROUTES.TALENT_PROFILE.name,
        component: () => {
            return import('@/components/Profile/Talent/index.vue');
        },
        meta: {
            alreadyLoggedIn: true,
        },
    },
    {
        path: ROUTES.TALENT_ONBOARDING.path,
        name: ROUTES.TALENT_ONBOARDING.name,
        component: () => {
            return import('@/components/Onboarding/Signup.vue');
        },
    },
    {
        path: ROUTES.ONBOARDING_UPLOAD_RESUME.path,
        name: ROUTES.ONBOARDING_UPLOAD_RESUME.name,
        component: () => {
            return import('@/components/Onboarding/UploadResume.vue');
        },
    },
    {
        path: ROUTES.ONBOARDING_CREATE_ACCOUNT.path,
        name: ROUTES.ONBOARDING_CREATE_ACCOUNT.name,
        component: () => {
            return import('@/components/Onboarding/AccountCreation.vue');
        },
    },
    {
        path: ROUTES.ONBOARDING_VERIFICATION_CODE.path,
        name: ROUTES.ONBOARDING_VERIFICATION_CODE.name,
        component: () => {
            return import('@/components/Onboarding/VerificationCode.vue');
        },
    },
    {
        path: ROUTES.TALENT_ACTIVATION.path,
        name: ROUTES.TALENT_ACTIVATION.name,
        component: () => {
            return import('@/components/Onboarding/TalentActivation.vue');
        },
    },
    {
        path: ROUTES.ACTIVATION_GET_STARTED.path,
        name: ROUTES.ACTIVATION_GET_STARTED.name,
        component: () => {
            return import('@/components/Onboarding/ActivationEntry.vue');
        },
    },
    {
        path: ROUTES.TALENT_ACTIVATION_INFO.path,
        name: ROUTES.TALENT_ACTIVATION_INFO.name,
        component: () => {
            return import('@/components/Onboarding/ActivationInfo.vue');
        },
    },
    {
        path: ROUTES.TALENT_LOGIN.path,
        name: ROUTES.TALENT_LOGIN.name,
        component: () => {
            return import('@/components/Onboarding/Login.vue');
        },
    },
    {
        path: ROUTES.TALENT_FORGOT_PASSWORD.path,
        name: ROUTES.TALENT_FORGOT_PASSWORD.name,
        component: () => {
            return import('@/components/Onboarding/ForgotPassword.vue');
        },
    },
    {
        path: ROUTES.TALENT_VERIFICATION_CODE.path,
        name: ROUTES.TALENT_VERIFICATION_CODE.name,
        component: () => {
            return import('@/components/Onboarding/CodeReset.vue');
        },
    },
    {
        path: ROUTES.TALENT_PASSWORD_RESET.path,
        name: ROUTES.TALENT_PASSWORD_RESET.name,
        component: () => {
            return import('@/components/Onboarding/PasswordReset.vue');
        },
    },
    {
        path: '/talent/*',
        redirect: ROUTES.TALENT_PROFILE.path,
    },
    {
        path: '*',
        redirect: ROUTES.ROOT.path,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            };
        }

        return {
            x: 0,
            y: 0,
            behavior: 'smooth',
        };
    },
});

router.beforeEach((to, from, next) => {
    if (to.name === from.name) {
        next();
        return;
    }

    if (
        to.matched.some((record) => {
            return record.meta.requiresAuth;
        }) &&
        !store.getters['app/user']
    ) {
        next({
            name: ROUTES.EMPLOYER_SIGNIN.name,
        });
        return;
    }

    if (to.meta.requiresAdmin && store.getters['app/user'].user_type !== 'admin') {
        next({
            path: from.path,
        });
        return;
    }

    if (
        to.matched.some((record) => {
            return record.meta.alreadyLoggedIn;
        }) &&
        store.getters['app/user']
    ) {
        next({
            name: ROUTES.ACCOUNT_DASHBOARD.name,
        });
        return;
    }

    const { user } = store.state.app;
    if (
        user &&
        user.subscription &&
        user.boss_mode === false &&
        !to.name.includes('settings') &&
        !to.query.sendInvoice
    ) {
        next({
            name: ROUTES.PAYMENT_METHOD.name,
            query: { payment: false },
        });
        return;
    }

    next();
});

const DEFAULT_TITLE = 'Talent Infusion';
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;
