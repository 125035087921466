<template>
    <div>
        <toast-factory />
        <notifications group="foo" position="center" />
        <router-view />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import downloadMixin from './mixins/downloadMixin';
import { activeNamespaces } from './store';

export default {
    name: 'App',
    mixins: [downloadMixin],
    created() {
        this.$GlobalEventEmitter.$on('navigate', (route) => {
            // setup the global event listener to navigate to any route from places where router instance does not exist, such as vuex actions
            // this global event listener will also remove the import/no-cycle eslint error caused by cyclic dependency between router and vuex
            this.$router.push(route).catch(() => {});
        });

        this.$GlobalEventEmitter.$on('logout', () => {
            // the argument `true` is passed to indicate that the user should be redirected back to their current page after logging in
            this.SIGN_OUT(true);
        });
    },
    methods: {
        ...mapActions(activeNamespaces.app, {
            SIGN_OUT: 'SIGN_OUT',
        }),
    },
};
</script>
