import axios from 'axios';
import debug from 'debug';

import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter';

// Create separate debug function for separate purposes of debugging
const debugHttpResponse = debug('ti:http:response');
const debugHttpRequestError = debug('ti:error:http:request');
const debugHttpResponseError = debug('ti:error:http:response');

// Create an instance of `AbortController` to allow cancelling the pending HTTP requests when needed
let abortController = new AbortController();

// Create a new instance of `axios` with Talent Infusion's base API config
export const baseAPI = axios.create({
    baseURL: process.env.VUE_APP_API,
});

// Add a request interceptor on this `instance` of `axios`
baseAPI.interceptors.request.use(
    (config) => {
        // Do something before the request is sent

        // Attach an `AbortSignal` to every outgoing HTTP request to allow cancelling the pending requests when needed
        const requestConfig = {
            signal: abortController.signal,
            ...config,
        };

        // Get access token from localStorage and add it to request headers
        const accessToken = localStorage.getItem('tiAccessToken');

        if (accessToken) {
            // Add the access token to the request headers and return the modified config object
            const newConfig = {
                ...requestConfig,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    ...requestConfig.headers,
                },
            };
            return newConfig;
        }

        // Return the original config object if no access token is found
        return requestConfig;
    },
    (error) => {
        if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            debugHttpRequestError('%o', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            debugHttpRequestError('Error %s', error.message);
        }

        // log a detailed information about the HTTP error
        debugHttpRequestError(error.toJSON());

        // Return the error object for next Promise chain
        return Promise.reject(error);
    },
);

// Add a response interceptor on this `instance` of `axios`
baseAPI.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger

        // Log the HTTP response on console
        debugHttpResponse(`${response.config.method} ${response.config.url} %o`, response.data);

        // Return the response to the calling function
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger

        if (axios.isCancel(error)) {
            // Request was cancelled explicitly by the user
            debugHttpResponseError(error.message);
        } else {
            if (error.response) {
                // Log the HTTP error info
                debugHttpResponseError(`${error.config.method} ${error.config.url} %o`, error.response.data);

                // Logout the user by clearing the Vuex store if response code is 401 (Unauthenticated)
                if (error.response.status === 401) {
                    // cancel all the pending HTTP requests so that this 401 error handler does not get triggered again
                    abortController.abort();

                    // also, regenerate the `AbortSignal` for future HTTP calls (since an `AbortSignal` cannot be reused after it is aborted)
                    abortController = new AbortController();

                    // emit a global event through event bus to logout the user from the application (by clearing their vuex state and redirecting them to the login screen)
                    GlobalEventEmitter.$emit('logout');
                }
            }

            // log a detailed information about the HTTP error
            debugHttpResponseError(error.toJSON());
        }

        // Return the error object for next Promise chain
        return Promise.reject(error);
    },
);
