import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import accountModule from '@/store/modules/account';
import appModule from '@/store/modules/app';
import downloadsModule from '@/store/modules/downloads';
import jobModule from '@/store/modules/job';
import talentProfileModule from '@/store/modules/talentProfile';

export const activeNamespaces = {
    app: 'app',
    talentProfile: 'talentProfile',
    job: 'job',
    account: 'account',
    downloads: 'downloads',
};

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: [activeNamespaces.app],
});

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        [activeNamespaces.app]: appModule,
        [activeNamespaces.talentProfile]: talentProfileModule,
        [activeNamespaces.job]: jobModule,
        [activeNamespaces.account]: accountModule,
        [activeNamespaces.downloads]: downloadsModule,
    },
    plugins: [vuexLocal.plugin],
});
