import { baseAPI } from '@/api/axios';
import { DOWNLOADS } from '@/api/endpoints';
import { DOWNLOAD_STATUES } from '@/components/Account/Settings/downloads-section/constants';
import { logger } from '@/utils/debug';
import { makeToast } from '@/utils/GlobalEventEmitter';
import showAPIErrorToast from '@/utils/showAPIErrorToast';

const handleErrorResponse = (err, dispatch, disableRequestIndicator = false) => {
    if (!disableRequestIndicator) {
        showAPIErrorToast(err);
        dispatch('SET_API_RESPONSE_STATUS', 'error');
    }
};

const initialState = {
    statuses: {}, // { [STATUS]: count }
    hasDownloadCompleted: false,
    downloads: [],
    pendingDownloads: [], // IDs of downloads that are pending
    isDownloadsPageActive: false, // if page is active then refetch "statuses count" on individual status change
};

const getters = {
    statuses: (state) => {
        return state.statuses;
    },
    hasDownloadCompleted: (state) => {
        return state.hasDownloadCompleted;
    },
    hasPendingDownloads: (state) => {
        return !!state.pendingDownloads.length;
    },
    downloads: (state) => {
        return state.downloads;
    },
};

const mutations = {
    RESET_STATE(state) {
        Object.assign(state, initialState);
    },
    SET_STATUSES(state, statuses) {
        state.statuses = statuses;
    },
    SET_HAS_DOWNLOAD_COMPLETED(state, value) {
        state.hasDownloadCompleted = value;
    },
    SET_IS_DOWNLOAD_PAGE_ACTIVE(state, value) {
        state.isDownloadsPageActive = value;
    },
    SET_DOWNLOADS(state, downloads) {
        state.downloads = downloads;
    },
    SET_PENDING_DOWNLOADS(state, pendingDownloads) {
        state.pendingDownloads = pendingDownloads;
    },
};

const actions = {
    RESET_STATE({ commit }) {
        commit('RESET_STATE');
    },
    SET_STATUSES({ commit }, statuses) {
        commit('SET_STATUSES', statuses);
    },
    SET_HAS_DOWNLOAD_COMPLETED({ commit }, value) {
        commit('SET_HAS_DOWNLOAD_COMPLETED', value);
    },
    SET_IS_DOWNLOAD_PAGE_ACTIVE({ commit }, value) {
        commit('SET_IS_DOWNLOAD_PAGE_ACTIVE', value);
    },
    SET_DOWNLOADS({ commit }, downloads) {
        commit('SET_DOWNLOADS', downloads);
    },
    UPDATE_DOWNLOAD_STATUS({ commit, state, dispatch }, { id, status }) {
        const downloads = state.downloads.map((download) => {
            if (download._id === id) {
                return { ...download, status };
            }
            return download;
        });
        commit('SET_DOWNLOADS', downloads);

        // side effect if download status is updated
        if (state.isDownloadsPageActive) {
            dispatch('FETCH_STATUSES');
        }
    },
    UPDATE_PENDING_DOWNLOADS({ commit, state }, ids) {
        commit('SET_PENDING_DOWNLOADS', [...state.pendingDownloads, ...ids]);
    },
    REMOVE_PENDING_DOWNLOAD({ commit, state }, id) {
        commit(
            'SET_PENDING_DOWNLOADS',
            state.pendingDownloads.filter((_id) => {
                return id !== _id;
            }),
        );
    },
    FETCH_STATUSES({ dispatch }) {
        baseAPI
            .get(DOWNLOADS.GET_STATUS_COUNT())
            .then(({ data }) => {
                const statuses = data.reduce((acc, { status, count }) => {
                    return { ...acc, [status]: count };
                }, {});
                dispatch('SET_STATUSES', statuses);
            })
            .catch((error) => {
                handleErrorResponse(error, dispatch);
            });
    },
    FETCH_PENDING_DOWNLOADS({ commit }) {
        baseAPI
            .post(DOWNLOADS.GET_DOWNLOADS_SEARCH(), {
                pageSize: 20,
                filterOptions: {
                    title: this.query,
                    statuses: [DOWNLOAD_STATUES.PENDING],
                },
            })
            .then((response) => {
                const data = (response.data.downloads || []).map(({ _id }) => {
                    return _id;
                });
                commit('SET_PENDING_DOWNLOADS', data);
            })
            .catch((error) => {
                logger.error('Unable to fetch pending downloads', error);
            });
    },
    DOWNLOAD_FILE({ dispatch }, { _id, title }) {
        baseAPI
            .get(DOWNLOADS.GET_URL(_id))
            .then(({ data }) => {
                makeToast({
                    variant: 'success',
                    msg: `${title} has been downloaded.`,
                });
                dispatch('UPDATE_DOWNLOAD_STATUS', {
                    id: _id,
                    status: DOWNLOAD_STATUES.DOWNLOADED,
                });
                window.open(data, '_blank');
            })
            .catch(() => {
                makeToast({
                    variant: 'danger',
                    msg: 'Cannot generate link for this download. Please try again after a while.',
                });
            });
    },
    FETCH_UPDATED_STATUS_OF_PENDING_DOWNLOADS({ dispatch, state }) {
        if (state.pendingDownloads.length) {
            baseAPI.post(DOWNLOADS.GET_UPDATES_ON_PENDING_DOWNLOADS(), state.pendingDownloads).then(({ data }) => {
                if (data.length) {
                    data.forEach((download) => {
                        dispatch('REMOVE_PENDING_DOWNLOAD', download._id);
                        dispatch('UPDATE_DOWNLOAD_STATUS', {
                            id: download._id,
                            status: download.status,
                        });

                        if (download.status === DOWNLOAD_STATUES.READY) {
                            makeToast({
                                variant: 'success',
                                msg: `Your file ${download.title} is ready for download. `,
                                action: {
                                    text: 'Download now',
                                    onClick: () => {
                                        dispatch('DOWNLOAD_FILE', download);
                                    },
                                },
                            });
                        }
                    });

                    if (!state.isDownloadsPageActive) {
                        dispatch('SET_HAS_DOWNLOAD_COMPLETED', true);
                    }
                }
            });
        }
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};
