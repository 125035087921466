import { mapActions, mapGetters } from 'vuex';

import { activeNamespaces } from '@/store';

export default {
    data() {
        return { pollingInterval: null };
    },
    computed: {
        ...mapGetters(activeNamespaces.downloads, {
            hasPendingDownloads: 'hasPendingDownloads',
        }),
        ...mapGetters(activeNamespaces.app, {
            user: 'user',
        }),
    },
    watch: {
        hasPendingDownloads() {
            if (this.hasPendingDownloads) {
                this.startPolling();
            } else {
                this.stopPolling();
            }
        },
        user(newUser, oldUser) {
            const tiAccessToken = localStorage.getItem('tiAccessToken');
            if (newUser?._id && newUser._id !== oldUser?._id && tiAccessToken) {
                this.fetchPendingDownloads();
            }
        },
    },
    mounted() {
        if (this.user?._id) {
            this.fetchPendingDownloads();
        }
    },
    beforeDestroy() {
        this.resetDownloadsState();
    },
    methods: {
        ...mapActions(activeNamespaces.downloads, {
            fetchPendingDownloads: 'FETCH_PENDING_DOWNLOADS',
            updatePendingDownloads: 'UPDATE_PENDING_DOWNLOADS',
            fetchUpdatedStatus: 'FETCH_UPDATED_STATUS_OF_PENDING_DOWNLOADS',
            resetDownloadsState: 'RESET_STATE',
        }),
        startPolling() {
            // poll after 5 seconds
            this.pollingInterval = setInterval(() => {
                this.fetchUpdatedStatus();
            }, 5000);
        },
        stopPolling() {
            if (this.pollingInterval) {
                clearInterval(this.pollingInterval);
            }
        },
    },
};
