const ACCOUNTS_RESOURCE = '/account';
export const ACCOUNTS = {
    ROOT() {
        return `${ACCOUNTS_RESOURCE}`;
    },
    GET_BILLING_CONTACT(accountId) {
        return `${ACCOUNTS_RESOURCE}/${accountId}/billing-contact`;
    },
    GET_ACCOUNT_USERS(accountId) {
        return `${ACCOUNTS_RESOURCE}/users/${accountId}`;
    },
    UPDATE_ACCOUNT_USER(userId) {
        return `${ACCOUNTS_RESOURCE}/users/${userId}`;
    },
    UPDATE_ACCOUNT_LOGO(accountId) {
        return `${ACCOUNTS_RESOURCE}/${accountId}/logo`;
    },
};

const ADD_ON_TOKENS_RESOURCE = '/add-on-tokens';
export const ADD_ON_TOKENS = {
    GET_TOKENS_LIST(subscriptionId) {
        return `${ADD_ON_TOKENS_RESOURCE}/${subscriptionId}/list`;
    },
};

const APPLICANTS_RESOURCE = '/applicant';
export const APPLICANTS = {
    ROOT() {
        return `${APPLICANTS_RESOURCE}`;
    },
    UPDATE_APPLICANT(applicantId) {
        return `${APPLICANTS_RESOURCE}/${applicantId}`;
    },
    ADD_TO_PIPELINE() {
        return `${APPLICANTS_RESOURCE}/addApplicantToPipeline`;
    },
};

const AUTH_RESOURCE = '/auth';
export const AUTH = {
    REGISTER_EMPLOYER() {
        return `${AUTH_RESOURCE}/register`;
    },
    REGISTER_TALENT() {
        return `${AUTH_RESOURCE}/register-talent`;
    },
    LOGIN_EMPLOYER() {
        return `${AUTH_RESOURCE}/login`;
    },
    LOGIN_TALENT() {
        return `${AUTH_RESOURCE}/login-talent`;
    },
    FORGOT_PASSWORD_EMPLOYER() {
        return `${AUTH_RESOURCE}/forgot-password`;
    },
    FORGOT_PASSWORD_TALENT() {
        return `${AUTH_RESOURCE}/forgot-password-talent`;
    },
    CHECK_CODE() {
        return `${AUTH_RESOURCE}/check-code`;
    },
    SEND_CODE() {
        return `${AUTH_RESOURCE}/send-code`;
    },
    RESEND_CODE_EMPLOYER() {
        return `${AUTH_RESOURCE}/resend-code`;
    },
    RESEND_CODE_TALENT() {
        return `${AUTH_RESOURCE}/resend-code-talent`;
    },
    RESET_PASSWORD_EMPLOYER() {
        return `${AUTH_RESOURCE}/reset-password`;
    },
    RESET_PASSWORD_TALENT() {
        return `${AUTH_RESOURCE}/reset-password-talent`;
    },
    GOOGLE_SIGNIN() {
        return `${AUTH_RESOURCE}/google`;
    },
    GOOGLE_SIGNUP() {
        return `${AUTH_RESOURCE}/google-signup`;
    },
    FETCH_CANNY_TOKEN() {
        return `${AUTH_RESOURCE}/canny_token`;
    },
    DECODE_JWT(jwt) {
        return `${AUTH_RESOURCE}/decode-jwt?jwt=${jwt}`;
    },
};

const DOWNLOADS_RESOURCE = '/downloads';
export const DOWNLOADS = {
    ROOT() {
        return `${DOWNLOADS_RESOURCE}`;
    },
    GET_DOWNLOADS_SEARCH: () => {
        return `${DOWNLOADS_RESOURCE}/list`;
    },
    GET_DUPLICATES: () => {
        return `${DOWNLOADS_RESOURCE}/check-duplicates`;
    },
    GET_FILENAME_CHECK: () => {
        return `${DOWNLOADS_RESOURCE}/check-title`;
    },
    GET_URL: (downloadID) => {
        return `${DOWNLOADS_RESOURCE}/url/${downloadID}`;
    },
    GET_STATUS_COUNT: () => {
        return `${DOWNLOADS_RESOURCE}/statuses`;
    },
    GET_UPDATES_ON_PENDING_DOWNLOADS: () => {
        return `${DOWNLOADS_RESOURCE}/check-statuses`;
    },
};

const EVENTS_RESOURCE = '/events';
export const EVENTS = {
    ROOT() {
        return `${EVENTS_RESOURCE}`;
    },
};

const INDUSTRIES_RESOURCE = '/industry';
export const INDUSTRIES = {
    ROOT() {
        return `${INDUSTRIES_RESOURCE}`;
    },
};

const INVITATIONS_RESOURCE = '/invitation';
export const INVITATIONS = {
    ROOT() {
        return `${INVITATIONS_RESOURCE}`;
    },
    GET_BY_ACCOUNT_ID(accountId) {
        return `${INVITATIONS_RESOURCE}/account/${accountId}`;
    },
    RESEND() {
        return `${INVITATIONS_RESOURCE}/resend`;
    },
};

const INVOICES_RESOURCE = '/invoice';
export const INVOICES = {
    ROOT() {
        return `${INVOICES_RESOURCE}`;
    },
};

const JOBS_RESOURCE = '/job';
export const JOBS = {
    ROOT() {
        return `${JOBS_RESOURCE}`;
    },
    GET_BY_ACCOUNT_ID(accountId) {
        return `${JOBS_RESOURCE}/account?accountId=${accountId}`;
    },
    UPDATE_JOB(jobId) {
        return `${JOBS_RESOURCE}/${jobId}`;
    },
    CREATE_AND_INVITE() {
        return `${JOBS_RESOURCE}/create-invite`;
    },
    DETAILS(jobId) {
        return `${JOBS_RESOURCE}/details/${jobId}`;
    },
};

export const LOCATIONS_RESOURCE = '/talent/location';
export const LOCATIONS = {
    AUTOCOMPLETE() {
        return `${LOCATIONS_RESOURCE}/autocomplete`;
    },
    PREDICTIONS() {
        return `${LOCATIONS_RESOURCE}/predictions`;
    },
};

const PAYMENTS_RESOURCE = '/payment';
export const PAYMENTS = {
    CREATE_BILLING_SESSION() {
        return `${PAYMENTS_RESOURCE}/billing`;
    },
    CREATE_ADD_ON_TOKENS_SESSION() {
        return `${PAYMENTS_RESOURCE}/add-on-tokens`;
    },
    CREATE_ONE_TIME_INVOICE() {
        return `${PAYMENTS_RESOURCE}/invoice`;
    },
    CONFIRM() {
        return `${PAYMENTS_RESOURCE}/confirm`;
    },
    GET_SUBSCRIPTION_PLANS() {
        return `${PAYMENTS_RESOURCE}/plans`;
    },
};

const SUBSCRIPTIONS_RESOURCE = '/subscription';
export const SUBSCRIPTIONS = {
    GET_PRODUCT_DETAILS(subscriptionId) {
        return `${SUBSCRIPTIONS_RESOURCE}/${subscriptionId}/product`;
    },
    GET_TOKENS(subscriptionId) {
        return `${SUBSCRIPTIONS_RESOURCE}/${subscriptionId}/tokens`;
    },
    USE_TOKENS(subscriptionId) {
        return `${SUBSCRIPTIONS_RESOURCE}/${subscriptionId}/useTokens`;
    },
    CONFIRM() {
        return `${SUBSCRIPTIONS_RESOURCE}/confirm`;
    },
};

const TALENTS_RESOURCE = '/talent';
export const TALENTS = {
    CREATE() {
        return `${TALENTS_RESOURCE}/create_talent`;
    },
    GET_BY_ID(talentId) {
        return `${TALENTS_RESOURCE}/talent_by_id/${talentId}`;
    },
    SEARCH() {
        return `${TALENTS_RESOURCE}/search`;
    },
    DOWNLOAD_SINGLE_RESUME(talentId) {
        return `${TALENTS_RESOURCE}/download/resumes/${talentId}`;
    },
    DOWNLOAD_RESUMES() {
        return `${TALENTS_RESOURCE}/download/resumes`;
    },
    DOWNLOAD_PROFILES() {
        return `${TALENTS_RESOURCE}/download/profiles`;
    },
    SKILLS() {
        return `${TALENTS_RESOURCE}/skills`;
    },
    COUNT_BY_SKILL() {
        return `${TALENTS_RESOURCE}/countTalentsBySkill`;
    },
    GET_TALENT_FOR_ACTIVATION(talentEmail) {
        return `${TALENTS_RESOURCE}/talent-activation/talent?email=${talentEmail}`;
    },
    GET_JOB_TITLES() {
        return `${TALENTS_RESOURCE}/titles`;
    },
    GET_COMPANIES_LIST() {
        return `${TALENTS_RESOURCE}/companies`;
    },
    GET_SIGNED_URL(type) {
        return `${TALENTS_RESOURCE}/signed-url/${type}`;
    },
    PARSE_RESUME(talentId) {
        return `${TALENTS_RESOURCE}/${talentId}/parse-talent-resume`;
    },
};

const TALENT_USERS_RESOURCE = '/talent';
export const TALENT_USERS = {
    GET_TALENT_BY_USER_ID(talentUserId) {
        return `${TALENT_USERS_RESOURCE}/${talentUserId}`;
    },
    UPDATE_TALENT_BY_USER_ID(talentUserId) {
        return `${TALENT_USERS_RESOURCE}/${talentUserId}`;
    },
    UPDATE_PROFILE_PHOTO(talentUserId) {
        return `${TALENT_USERS_RESOURCE}/${talentUserId}/profilePhoto`;
    },
    UPLOAD_RESUME(talentUserId) {
        return `${TALENT_USERS_RESOURCE}/${talentUserId}/resume`;
    },
    CREATE_PROFESSIONAL_EXPERIENCE(talentUserId) {
        return `${TALENT_USERS_RESOURCE}/professionalExperience/${talentUserId}`;
    },
    UPDATE_PROFESSIONAL_EXPERIENCE(talentUserId) {
        return `${TALENT_USERS_RESOURCE}/professionalExperience/${talentUserId}`;
    },
    DELETE_PROFESSIONAL_EXPERIENCE(talentUserId, professionalExperienceId) {
        return `${TALENT_USERS_RESOURCE}/${talentUserId}/professionalExperience/${professionalExperienceId}`;
    },
    CREATE_QUALIFICATION(talentUserId) {
        return `${TALENT_USERS_RESOURCE}/qualification/${talentUserId}`;
    },
    UPDATE_QUALIFICATION(talentUserId) {
        return `${TALENT_USERS_RESOURCE}/qualification/${talentUserId}`;
    },
    DELETE_QUALIFICATION(talentUserId, qualificationId) {
        return `${TALENT_USERS_RESOURCE}/${talentUserId}/qualification/${qualificationId}`;
    },
};

const USERS_RESOURCE = '/user';
export const USERS = {
    ROOT(userId) {
        return `${USERS_RESOURCE}/${userId}`;
    },
    GET_STATUS_BY_ACCOUNT(accountId) {
        return `${USERS_RESOURCE}/statuses/${accountId}`;
    },
    GET_TYPES_BY_ACCOUNT(accountId) {
        return `${USERS_RESOURCE}/types/${accountId}`;
    },
    INVITE() {
        return `${USERS_RESOURCE}/invite`;
    },
    RESEND_INVITATION(userId) {
        return `${USERS_RESOURCE}/resend-invitation/${userId}`;
    },
    ARCHIVE(userId) {
        return `${USERS_RESOURCE}/archive/${userId}`;
    },
};

const ZENDESK_RESOURCE = '/zendesk';
export const ZENDESK = {
    TOKEN() {
        return `${ZENDESK_RESOURCE}/token`;
    },
};
