import { v4 as uuidv4 } from 'uuid';

import { baseAPI } from '@/api/axios';
import { INVOICES, SUBSCRIPTIONS, USERS } from '@/api/endpoints';
import { EMPLOYER_SIGNIN, TALENT_LOGIN } from '@/router/routes';
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter';

const initialTokensDetails = {
    availableTokens: 0,
    isLessThanTenPercent: false,
    tokenResetDate: '',
    totalTokens: 0,
    usedTokens: 0,
};

const initialState = {
    token: '',
    talentUserId: null,
    talentResultCount: null,
    user: null,
    lastTime: new Date(),
    viewedTalentIds: [],
    forgotEmail: '',
    resetPasswordToken: '',
    cannyToken: null,
    talenResumeUpload: null,
    placesAPISessionToken: null,
    filtersSidebarExpanded: false,
    filtersCount: 0,
    filterOptions: {},
    tokensDetails: initialTokensDetails,
    productDetails: null,
    blockedAccountStatuses: [
        'incomplete',
        'incomplete_expired',
        'trialing',
        'past_due',
        'canceled',
        'unpaid',
        'paused',
    ],
    invoiceDetails: null,
};

const moduleGetters = {
    isTalentViewed: ({ viewedTalentIds }) => {
        return (id) => {
            return viewedTalentIds.includes(id);
        };
    },
    talentUserId: ({ talentUserId }) => {
        return talentUserId;
    },
    lastTime: ({ lastTime }) => {
        return new Date(lastTime);
    },
    token: ({ token }) => {
        return token;
    },
    cannyToken: ({ cannyToken }) => {
        return cannyToken;
    },
    talentResultCount: ({ talentResultCount }) => {
        return talentResultCount;
    },
    talenResumeUpload: ({ talenResumeUpload }) => {
        return talenResumeUpload;
    },
    filtersSidebarExpanded: ({ filtersSidebarExpanded }) => {
        return filtersSidebarExpanded;
    },
    filtersCount: ({ filtersCount }) => {
        return filtersCount;
    },
    user: ({ user }) => {
        if (!user) {
            return null;
        }

        const { first_name: firstName, last_name: lastName, email } = user;
        let avatar = '';
        let fullName = '';

        if (firstName && lastName) {
            fullName = `${firstName} ${lastName}`;
        } else if (firstName) {
            fullName = firstName;
        } else if (lastName) {
            fullName = lastName;
        }

        avatar = fullName || email;

        return { ...user, avatar, fullName };
    },
    placesAPISessionToken: ({ placesAPISessionToken }) => {
        return placesAPISessionToken;
    },
    filterOptions: ({ filterOptions }) => {
        return filterOptions;
    },
    subscription: ({ user }) => {
        return user?.subscription ?? {};
    },
    tokensInfo: ({ tokensInfo }) => {
        return tokensInfo;
    },
    productDetails: ({ productDetails }) => {
        return productDetails;
    },
    tokensDetails: ({ tokensDetails }) => {
        return tokensDetails;
    },
    availableTokens: ({ tokensDetails }) => {
        return tokensDetails.availableTokens;
    },
    blockedAccountStatuses: ({ blockedAccountStatuses }) => {
        return blockedAccountStatuses;
    },
    invoiceDetails: ({ invoiceDetails }) => {
        return invoiceDetails;
    },
};

const mutations = {
    INSERT_TALENT_ID_INTO_VIEWED_LIST(state, payload) {
        if (state.viewedTalentIds.includes(payload)) {
            return;
        }

        state.viewedTalentIds.push(payload);
    },
    SET_LAST_TIME(state, payload) {
        const lastTime = new Date(state.lastTime);
        const millisecondsDiff = payload.getTime() - lastTime.getTime();
        const daysDiff = Math.round(millisecondsDiff / (24 * 60 * 60 * 60));

        if (daysDiff < 1) {
            return;
        }

        state.lastTime = payload;
    },
    SET_TOKEN(state, payload) {
        state.token = payload;
    },
    SET_TALENT_USER_ID(state, payload) {
        state.talentUserId = payload;
    },
    SET_CANNY_TOKEN(state, payload) {
        state.cannyToken = payload;
    },
    SET_TALENT_RESULT_COUNT(state, payload) {
        state.talentResultCount = payload;
    },
    SET_TALENT_RESUME_UPLOAD(state, payload) {
        state.talenResumeUpload = payload;
    },
    SET_USER(state, payload) {
        state.user = payload;
    },
    SET_EMAIL_FOR_FORGOT_PASSWORD(state, payload) {
        state.forgotEmail = payload;
    },
    SET_RESET_PASSWORD_TOKEN(state, payload) {
        state.resetPasswordToken = payload;
    },
    SET_PLACES_API_SESSION_TOKEN(state, payload) {
        state.placesAPISessionToken = payload;
    },
    SET_FILTERS_SIDEBAR_EXPANDED(state, payload) {
        state.filtersSidebarExpanded = payload;
    },
    SET_FILTERS_COUNT(state, payload) {
        state.filtersCount = payload;
    },
    SET_FILTER_OPTIONS(state, payload) {
        state.filterOptions = payload;
    },
    SET_TOKENS_DETAILS(state, payload) {
        state.tokensDetails = payload;
    },
    SET_PRODUCT_DETAILS(state, payload) {
        state.productDetails = payload;
    },
    SET_INVOICE_DETAILS(state, payload) {
        state.invoiceDetails = payload;
    },
    RESET_STATE(state) {
        Object.assign(state, initialState);
    },
};

const actions = {
    async FETCH_USER({ commit, state }) {
        await baseAPI.get(USERS.ROOT(state.user?._id)).then(({ data }) => {
            let id = null;
            if (data.subscription !== undefined) {
                id = data.subscription._id;
            }

            const fetchData = async () => {
                const response = await baseAPI.get(SUBSCRIPTIONS.GET_TOKENS(id));
                commit('SET_TOKENS_DETAILS', response.data);
            };

            if (id) {
                fetchData();
            }

            commit('SET_USER', data);
        });
    },
    async FETCH_INVOICE_DETAILS({ commit }) {
        const response = await baseAPI.get(INVOICES.ROOT());
        commit('SET_INVOICE_DETAILS', response.data);
    },
    SET_LAST_TIME({ commit }) {
        commit('SET_LAST_TIME', new Date());
    },
    SET_TOKEN({ commit }, payload) {
        commit('SET_TOKEN', payload);
    },
    SET_TALENT_USER_ID({ commit }, payload) {
        commit('SET_TALENT_USER_ID', payload);
    },
    SET_CANNY_TOKEN({ commit }, payload) {
        commit('SET_CANNY_TOKEN', payload);
    },
    SET_TALENT_RESULT_COUNT({ commit }, payload) {
        commit('SET_TALENT_RESULT_COUNT', payload);
    },
    SET_TALENT_RESUME_UPLOAD({ commit }, payload) {
        commit('SET_TALENT_RESUME_UPLOAD', payload);
    },
    SET_EMAIL_FOR_FORGOT_PASSWORD({ commit }, payload) {
        commit('SET_EMAIL_FOR_FORGOT_PASSWORD', payload);
    },
    SET_RESET_PASSWORD_TOKEN({ commit }, payload) {
        commit('SET_RESET_PASSWORD_TOKEN', payload);
    },
    SET_USER({ commit }, payload) {
        commit('SET_USER', payload);
    },
    SIGN_OUT({ commit, state }, allowRedirect = false) {
        // read the `talentUserId` from the state before resetting it
        const { talentUserId } = state;

        // reset the state to its initial values
        commit('RESET_STATE');

        // also, remove the access token from the local storage
        localStorage.removeItem('tiAccessToken');

        // Redirect the user to an appropriate login screen after logging them out (clearing the persisted state) based on their role
        let loginPath = EMPLOYER_SIGNIN.path;
        if (talentUserId) {
            loginPath = TALENT_LOGIN.path;
        }

        // for adding redirection feature, get the current relative URL to redirect the user back to the same page after successful login
        const currentRelativeURL = window.location.href.replace(window.location.origin, '');

        // Emit the global event to navigate the user to the login screen
        GlobalEventEmitter.$emit('navigate', {
            path: loginPath,
            query: allowRedirect ? { redirect: currentRelativeURL } : {},
        });
    },
    UPDATE_USER({ dispatch, getters }, payload) {
        const { token, user } = getters;

        if (!token || !user) {
            return undefined;
        }

        return new Promise((resolve, reject) => {
            baseAPI
                .put(USERS.ROOT(user?._id), payload)
                .then(({ data }) => {
                    const { token: newAuthToken, updatedUser } = data;

                    localStorage.setItem('tiAccessToken', newAuthToken);
                    dispatch('SET_TOKEN', newAuthToken);
                    dispatch('SET_USER', {
                        ...user,
                        ...updatedUser,
                    });

                    return resolve({
                        ...user,
                        ...updatedUser,
                    });
                })
                .catch((ex) => {
                    return reject(ex);
                });
        });
    },
    VIEWED_TALENT({ commit }, payload) {
        commit('INSERT_TALENT_ID_INTO_VIEWED_LIST', payload);
    },
    GENERATE_PLACES_API_SESSION_TOKEN({ commit }) {
        commit('SET_PLACES_API_SESSION_TOKEN', uuidv4());
    },
    INVALIDATE_PLACES_API_SESSION_TOKEN({ commit }) {
        commit('SET_PLACES_API_SESSION_TOKEN', null);
    },
    SET_FILTERS_SIDEBAR_EXPANDED({ commit }, payload) {
        commit('SET_FILTERS_SIDEBAR_EXPANDED', payload);
    },
    SET_FILTERS_COUNT({ commit }, payload) {
        commit('SET_FILTERS_COUNT', payload);
    },
    SET_TOKENS_DETAILS({ commit }, payload) {
        commit('SET_TOKENS_DETAILS', payload);
    },
    SET_FILTER_OPTIONS({ commit }, payload) {
        commit('SET_FILTER_OPTIONS', payload);
    },
    SET_PRODUCT_DETAILS({ commit }, payload) {
        commit('SET_PRODUCT_DETAILS', payload);
    },
    FETCH_TOKENS_DETAILS({ dispatch, getters }) {
        const { user } = getters;
        if (user.subscription?._id) {
            const subscriptionID = user.subscription._id;

            baseAPI.get(SUBSCRIPTIONS.GET_TOKENS(subscriptionID)).then(({ data }) => {
                dispatch('SET_TOKENS_DETAILS', data);
            });
        }
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters: moduleGetters,
    mutations,
    actions,
};
