import { baseAPI } from '@/api/axios';
import { ACCOUNTS, USERS } from '@/api/endpoints';
import showAPIErrorToast from '@/utils/showAPIErrorToast';

const handleErrorResponse = (err, dispatch, disableRequestIndicator = false) => {
    if (!disableRequestIndicator) {
        showAPIErrorToast(err);
        dispatch('SET_API_RESPONSE_STATUS', 'error');
    }
};

const initialState = {
    users: [],
    statuses: [],
    user_types: [],
    apiResponseStatus: '',
};

const getters = {
    users: (state) => {
        return state.users;
    },
    statuses: (state) => {
        return state.statuses;
    },
    user_types: (state) => {
        return state.user_types;
    },
    apiResponseStatus: ({ apiResponseStatus }) => {
        return apiResponseStatus;
    },
};

const mutations = {
    SET_USERS(state, users) {
        state.users = users;
    },
    SET_STATUSES(state, statuses) {
        state.statuses = statuses;
    },
    SET_USER_TYPES(state, userTypes) {
        state.user_types = userTypes;
    },
    SET_API_RESPONSE_STATUS(state, apiResponseStatus) {
        state.apiResponseStatus = apiResponseStatus;
    },
};

const actions = {
    async FETCH_USERS_BY_ACCOUNT({ dispatch, rootState }) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        baseAPI
            .get(ACCOUNTS.GET_ACCOUNT_USERS(rootState.app.user.account_id))
            .then(({ data }) => {
                dispatch('SET_USERS', data);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    async FETCH_USERS_STATUSES_BY_ACCOUNT({ dispatch, rootState }) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        baseAPI
            .get(USERS.GET_STATUS_BY_ACCOUNT(rootState.app.user.account_id))
            .then(({ data }) => {
                dispatch('SET_STATUSES', data);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    async UPDATE_COMPANY_NAME({ dispatch }, payload) {
        return baseAPI.put(ACCOUNTS.ROOT(), payload).catch((err) => {
            handleErrorResponse(err, dispatch);
        });
    },
    async FETCH_USER_TYPES_BY_ACCOUNT({ dispatch, rootState }) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        baseAPI
            .get(USERS.GET_TYPES_BY_ACCOUNT(rootState.app.user.account_id))
            .then(({ data }) => {
                dispatch('SET_USER_TYPES', data);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    SET_USERS({ commit }, users) {
        commit('SET_USERS', users);
    },
    SET_STATUSES({ commit }, statuses) {
        commit('SET_STATUSES', statuses);
    },
    SET_USER_TYPES({ commit }, userTypes) {
        commit('SET_USER_TYPES', userTypes);
    },
    SET_API_RESPONSE_STATUS({ commit }, apiResponseStatus) {
        commit('SET_API_RESPONSE_STATUS', apiResponseStatus);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};
