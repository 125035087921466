export const DOWNLOAD_STATUES = Object.freeze({
    PENDING: 'pending',
    DOWNLOADED: 'downloaded',
    READY: 'ready',
    FAILED: 'failed',
});

export const STATUS_FILTER_OPTIONS = [
    { title: 'Pending', id: DOWNLOAD_STATUES.PENDING, count: 0, isSelected: false },
    { title: 'Downloaded', id: DOWNLOAD_STATUES.DOWNLOADED, count: 0, isSelected: false },
    { title: 'Ready', id: DOWNLOAD_STATUES.READY, count: 0, isSelected: false },
    { title: 'Failed', id: DOWNLOAD_STATUES.FAILED, count: 0, isSelected: false },
];

export const DEFAULT_DOWNLOADS_PAGE_SIZE = 20;
