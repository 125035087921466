import { init as initFullStory, FullStory } from '@fullstory/browser';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vue from 'vue';
import vueClickOutsideElement from 'vue-click-outside-element';
import VueLazyload from 'vue-lazyload';
import VueMoment from 'vue-moment';
import Notifications from 'vue-notification';
import VueRx from 'vue-rx';
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd.min';
import VueLoadmore from 'vuejs-loadmore';

import { baseAPI } from '@/api/axios';
import ToastFactory from '@/components/ToastFactory.vue';
import * as ROUTES from '@/router/routes';
import { logger } from '@/utils/debug';
import { makeToast, GlobalEventEmitter } from '@/utils/GlobalEventEmitter';

import App from './App.vue';
import router from './router';
import store from './store/index';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vuejs-clipper/dist/vuejs-clipper.css';
import '@/assets/css/main.css';

Vue.use(VueRx);
Vue.use(VuejsClipper);

Vue.prototype.$Pendo = pendo;

if (process.env.VUE_APP_ENV === 'prod') {
    initFullStory({ orgId: 'o-1Q249R-na1' });
    Vue.prototype.$FullStory = FullStory;
}

Vue.component('ToastFactory', ToastFactory);

Vue.use(VueMoment);
Vue.use(vueClickOutsideElement);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Notifications);
Vue.use(VueLoadmore, {
    lang: 'en-US',
});
Vue.use(VueLazyload);

Vue.component('ToastFactory', ToastFactory);

Vue.prototype.$api = baseAPI;
Vue.prototype.$routes = ROUTES;
Vue.prototype.$GlobalEventEmitter = GlobalEventEmitter;
Vue.prototype.$toast = makeToast;
Vue.prototype.$console = logger;

Vue.config.productionTip = false;

router.afterEach(() => {
    window.dispatchEvent(new Event('close-zendesk'));
});

new Vue({
    store,
    router,
    render: (h) => {
        return h(App);
    },
}).$mount('#app');
