/**
 * @fileoverview
 * This file exports all the routes in the application in the following format:
 * ```js
 * {
 *    path: '/absolute/path/to/route', // notice the leading slash '/' at the beginning of the path
 *    name: 'a-unique-name-for-the-route',
 * }
 * ```
 *
 * This is useful for referencing routes (or their names) anywhere in the application without having to hard-code the paths.
 */

export const ROOT = {
    path: '/',
    name: 'home',
};

export const FORGOT_PASSWORD = {
    path: '/forgot-password',
    name: 'forgot-password',
};

export const RESET_PASSWORD = {
    path: '/reset-password',
    name: 'reset-password',
};

export const RESET_CONFIRMATION = {
    path: '/reset-confirmation',
    name: 'reset-confirmation',
};

export const EMPLOYER_SIGNUP = {
    path: '/signup',
    name: 'signup',
};

export const VERIFICATION_CODE = {
    path: '/verification-code',
    name: 'verification-code',
};

export const ENTERPRISE_ONBOARDING_PLANS = {
    path: '/enterprise/onboarding/plans',
    name: 'enterprise-onboarding-plans',
};

export const EMPLOYER_SIGNIN = {
    path: '/signin',
    name: 'signin',
};

const ACCOUNT_PREFIX = '/account';
export const ACCOUNT_ROOT = {
    path: ACCOUNT_PREFIX,
    name: 'account',
};

export const TALENT_DETAILS = {
    path: `${ACCOUNT_PREFIX}/talent/details/:id`,
    name: 'account-details',
};

export const ACCOUNT_DASHBOARD = {
    path: `${ACCOUNT_PREFIX}/dashboard`,
    name: 'account-dashboard',
};

export const ACCOUNT_SEARCH = {
    path: `${ACCOUNT_PREFIX}/search`,
    name: 'account-search',
};

const ACCOUNT_SETTINGS_PREFIX = `${ACCOUNT_PREFIX}/settings`;
export const ACCOUNT_SETTINGS = {
    path: ACCOUNT_SETTINGS_PREFIX,
    name: 'account-settings',
};

export const PAYMENT_METHOD = {
    path: `${ACCOUNT_SETTINGS_PREFIX}/payment-method`,
    name: 'account-settings-payment-method',
};

export const COMPANY_INFO = {
    path: `${ACCOUNT_SETTINGS_PREFIX}/company-info`,
    name: 'account-settings-company-info',
};

export const ACCOUNT_TEAM = {
    path: `${ACCOUNT_SETTINGS_PREFIX}/team`,
    name: 'account-settings-team',
};

export const ACCOUNT_DOWNLOADS = {
    path: `${ACCOUNT_SETTINGS_PREFIX}/downloads`,
    name: 'account-settings-downloads',
};

const JOBS_PREFIX = '/jobs';
export const JOBS = {
    path: JOBS_PREFIX,
    name: 'jobs',
};

export const JOB_DETAILS = {
    path: `${JOBS_PREFIX}/details/:id`,
    name: 'job-details',
};

export const ARCHIVED_JOBS = {
    path: `${JOBS_PREFIX}/archived`,
    name: 'archived-jobs',
};

export const ARCHIVED_JOB_DETAILS = {
    path: `${JOBS_PREFIX}/archived/details/:id`,
    name: 'archived-job-details',
};

export const ADD_JOB = {
    path: `${JOBS_PREFIX}/add`,
    name: 'add-job',
};

export const TALENT_PROFILE = {
    path: '/talent/profile',
    name: 'talent-profile',
};

const TALENT_ONBOARDING_PREFIX = '/talent/onboarding';
export const TALENT_ONBOARDING = {
    path: TALENT_ONBOARDING_PREFIX,
    name: 'onboarding',
};

export const ONBOARDING_UPLOAD_RESUME = {
    path: `${TALENT_ONBOARDING_PREFIX}/uploadresume`,
    name: 'onboarding-uploadresume',
};

export const ONBOARDING_CREATE_ACCOUNT = {
    path: `${TALENT_ONBOARDING_PREFIX}/createaccount`,
    name: 'onboarding-create-account',
};

export const ONBOARDING_VERIFICATION_CODE = {
    path: `${TALENT_ONBOARDING_PREFIX}/verificationcode`,
    name: 'onboarding-verification-code',
};

const TALENT_ACTIVATION_PREFIX = '/talent/activation';
export const TALENT_ACTIVATION = {
    path: `${TALENT_ACTIVATION_PREFIX}/activate`,
    name: 'talent-activation',
};

export const ACTIVATION_GET_STARTED = {
    path: `${TALENT_ACTIVATION_PREFIX}/get-started`,
    name: 'activation-get-started',
};

export const TALENT_ACTIVATION_INFO = {
    path: `${TALENT_ACTIVATION_PREFIX}/info`,
    name: 'activation-info',
};

export const TALENT_LOGIN = {
    path: '/talent/login',
    name: 'onboarding-login',
};

export const TALENT_FORGOT_PASSWORD = {
    path: '/talent/forgot-password',
    name: 'onboarding-forgot-password',
};

export const TALENT_VERIFICATION_CODE = {
    path: '/talent/codereset',
    name: 'onboarding-verification-code',
};

export const TALENT_PASSWORD_RESET = {
    path: '/talent/passwordreset',
    name: 'onboarding-password-reset',
};
